














import { PropType } from 'vue';
import { defineComponent } from '@nuxtjs/composition-api';
import { ContactPointInterface } from '~/modules/amplience/types';

export default defineComponent({
  name: 'ContactPoints',
  props: {
    items: {
      type: Array as PropType<ContactPointInterface[]>
    },
    position: {
      type: String,
      default: 'right',
    },
  },
});
