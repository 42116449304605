
































import { PropType } from 'vue';
import { defineComponent } from '@nuxtjs/composition-api';
import type { MappedImage, NavigationInterface, ContactPointsInterface, SocialNetworksInterface } from '~/modules/amplience/types';
import Copyright from '~/modules/amplience/components/atoms/Copyright.vue';

export default defineComponent({
  name: 'AmplienceFooter',
  components: {
    Copyright,
  },
  props: {
    image: {
      type: Object as PropType<MappedImage>,
      default: () => {},
    },
    navigation: {
      type: Object as PropType<NavigationInterface>,
      default: () => {},
    },
    socialNetworks: {
      type: Object as PropType<SocialNetworksInterface>,
      default: () => {},
    },
    contactPoints: {
      type: Object as PropType<ContactPointsInterface>,
      default: () => {},
    },
    copyright: {
      type: String,
      default: '',
    },
  },
  setup() {
    const imageUrl = (image): string => image && image.url ? image.url : '/bbr/warrant-logo-white.png';
    const imageAlt = (image): string => image && image.alt ? image.alt : 'footer-logo';

    return {
      imageUrl,
      imageAlt,
    }
  }
});
