









import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Copyright',
  props: {
    copyright: {
      type: String,
    },
  },
});
