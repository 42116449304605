















import { PropType } from 'vue';
import { defineComponent } from '@nuxtjs/composition-api';
import { SocialNetworkInterface } from '~/modules/amplience/types';

export default defineComponent({
  name: 'SocialNetworks',
  props: {
    items: {
      type: Array as PropType<SocialNetworkInterface[]>,
    },
  },
});
